<template>
    <div class="confirm-container" v-if="!loading">
        <div class="confirm-content">
            <div class="confirm-title">
                {{ taskDetail.title }}
            </div>

            <p class="sub-title">中标方案</p>

            <img v-for="img in taskDetail.image" :key="img" class="confirm-img" :src="$fillOssPath(img)" alt="" />

            <div class="confirm-file">
                <p class="sub-title">交付文件明细</p>
                <ul class="file-list">
                    <div v-for="(group,key) in taskDetail.delivery_contents" :key="key">
                        <li
                            class="file-list-item"
                            v-for="(content, title) of group"
                            :key="content.id"
                        >
                            <span class="label">{{ title }}</span>
                            <template v-if="isArray(content)">
                                <div
                                    v-for="(file, index) in content"
                                    :key="index"
                                    class="file-item-content"
                                >
                                    {{ decodeURI(file) | formatFileName }}
                                </div>
                            </template>
                            <div v-else class="file-item-content">{{ content }}</div>
                        </li>
                    </div>
                </ul>
            </div>

            <div class="confirm-explain">
                <div class="sub-title">结项说明</div>
                <ul class="explain-list">
                    <li class="list-content">
                        1.甲方应对其指定的项目负责人就本项目的验收确认等行为负责，该项目负责人视为甲方的授权代表，其操作确认行为同甲方的盖章行为具有同等法律效益
                    </li>
                    <li class="list-content">
                        2.甲方选定并付清费用的方案知识产权归甲方所有，甲方未选定的方案知识产权归乙方所有。
                    </li>
                </ul>
            </div>
        </div>
        <!-- 操作 -->
        <div class="opeartion-box" v-if="taskDetail.task_status !== 5">
            <van-button round type="primary" @click="handleConfirm">确认结项</van-button>
        </div>

        <!-- 结果展示 -->
        <lk-result
            :visible="resultVisible"
            title="提交成功"
            confirm-button-text="查看结项确认书"
            :confirm-button-handler="onConfirmButtonClick"
        >
            已生成 结项确认书 ，邦女郎将根据您选择的方案整理成最终的交付文件，请耐心等待
        </lk-result>
    </div>
</template>

<script>
import LkResult from '@/components/LkResult';
import { getFinalConfirm, putFinalConfirm } from '@/api/task';

export default {
    name: 'task-confirm',
    components: { LkResult },
    computed: {
        taskNo() {
            return this.$route.params.taskNo;
        },
    },
    data() {
        return {
            taskDetail: {},
            loading: true,
            resultVisible: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            getFinalConfirm(this.taskNo).then(([response, error]) => {
                if (!error) {
                    this.loading = false;
                    this.taskDetail = response.data;
                } else {
                    this.$toast({
                        message: error.msg,
                        duration: 0,
                    });
                }
            });
        },
        handleConfirm() {
            this.$dialog
                .confirm({
                    message: '要完结项目么？',
                    confirmButtonText: '结项',
                })
                .then(() => {
                    putFinalConfirm(this.taskNo).then(([, error]) => {
                        if (!error) {
                            this.resultVisible = true;
                        } else {
                            this.$toast(error.msg);
                        }
                    });
                });
        },
        // TODO: 重新获取 目前缺少数据执行完毕再关闭结果的操作
        onConfirmButtonClick() {
            this.init();
            this.resultVisible = false;
        },

        isArray(obj) {
            return Array.isArray(obj);
        },
    },
};
</script>

<style lang="scss" scoped>
@import './styles/index.scss';
.confirm-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    .confirm-content {
        flex: 1;
        padding: 0 1rem 1.6rem;
        overflow-y: scroll;
        // 标题
        .confirm-title {
            margin-top: 1.5rem;
            font-size: 1.3rem;
            line-height: 1.5;
            font-weight: bold;
        }
        // 副标题
        .sub-title {
            margin-top: 0.8rem;
            margin-bottom: 0.4rem;
            font-size: 1rem;
            line-height: 1.5;
            font-weight: bold;
        }

        .confirm-img {
            display: block;
            width: 100%;
            margin-bottom: 1.6rem;
        }
        .confirm-file {
            .file-subTitle {
                color: #999;
                font-size: 0.8rem;
                line-height: 1.4rem;
            }
            .file-list {
                margin-top: 0.5rem;
                .file-list-item {
                    line-height: 1.5rem;
                    font-size: 0.8rem;
                    .label {
                        position: relative;
                        display: inline-block;
                        font-size: 0.875rem;
                        color: #666;
                        &::before {
                            display: inline-block;
                            content: '';
                            width: 0.3rem;
                            height: 0.3rem;
                            border-radius: 50%;
                            background-color: #666;
                            margin-right: 0.5rem;
                            vertical-align: middle;
                        }
                    }
                    .file-item-content {
                        padding-left: 1rem;
                        font-size: 0.875rem;
                        color: #666;
                    }
                }
            }
        }
        .confirm-explain {
            margin-top: 1.6rem;
            .explain-title {
                font-size: 1.2rem;
                line-height: 1.4rem;
            }
            .explain-list {
                background: #ededed;
                border-radius: 0.5rem;
                margin-top: 0.5rem;
                padding: 0.7rem;
                .list-content {
                    line-height: 1.5;
                    font-size: 0.875rem;
                    color: #666;
                    text-align: justify;
                }
                .list-content:nth-child(2) {
                    margin-top: 0.5rem;
                }
            }
        }
    }
}
</style>
