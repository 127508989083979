/**
 * @description 任务相关接口
 */
import request from '@/utils/request';

/**
 *
 * @param {String} taskNo 任务编码
 * @param {Number} code 计划编号
 */
export function getProposal(taskNo, code) {
    return request({
        url: `/v1/task/${taskNo}/${code}`,
        method: 'get',
    });
}

export function putConfirmProposal(proposalId, data) {
    return request({
        url: `/v1/task/proposal/${proposalId}`,
        method: 'put',
        data,
    });
}

// 获取提案笔记
export function getProposalNote(taskNo, code) {
    return request({
        url: `/v1/task/proposal/${taskNo}/${code}/item`,
        method: 'get',
    });
}

// 都不满意
export function putProposalReject(proposalId, reason, remark) {
    return request({
        url: `/v1/task/proposal/${proposalId}/uncheck`,
        method: 'put',
        data: {
            reason,
            remark,
        },
    });
}

// 获取结项确认书
export function getFinalConfirm(taskNo) {
    return request({
        url: `/v1/task/${taskNo}`,
        method: 'get',
    });
}

// 确认结项
export function putFinalConfirm(taskNo) {
    return request({
        url: `/v1/task/${taskNo}`,
        method: 'put',
    });
}
